import React from "react";

function GetInTouch() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading">Get in touch!</h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <h1 className=" text-3xl md:text-5xl text-center md:text-left font-bold text-black-default">
            For all types of inquiries:
            <br />{" "}
            <a href="mailto:engel@netmark.de" className="hover:text-green-default underline">
              engel@netmark.de
            </a>
          </h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <h1 className=" text-3xl md:text-5xl text-center md:text-left font-bold text-black-default">
            Technical administration:
            <br />{" "}
            <a href="mailto:bittner@mailbox.org" className="hover:text-green-default underline">
              bittner@mailbox.org
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
