import React from "react";

function Contact() {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl mx-auto px-10 pt-20 md:pb-20 pb-10 ">
        <div className="md:pb-20 pb-10 ">
          <h1 className="heading">Contact</h1>
        </div>
        <div>
          <p className="mb-10 text-white text-lg">
            Rüdiger Engel
            <br />
            Rosenberg 20
            <br />
            38640 Goslar
          </p>

          <p className="mb-10 text-white text-lg">
            engel@netmark.de
            <br />
            0049 5321 3938 066
            <br />
            0049 15787222448
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
