import React from "react";
import Videosection from "./Videosection";
import Layout from "./../Layout/Layout";
import GetInTouch from "./GetInTouch";
import Interested from "./../Home/Interested";
import Contact from "./Contact";
import AccordinTabs from "./AccordinTabs";

const Index = () => {
  return (
    <Layout>
      <Videosection />
      <GetInTouch />
      <Interested />
      <Contact />
      <AccordinTabs />
    </Layout>
  );
};

export default Index;
